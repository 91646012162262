import ChevronDownIcon from 'assets/icons/chevron-down.svg'
import { FC, forwardRef, ReactNode, RefAttributes } from 'react'
import { ButtonBaseProps } from '../buttonBaseTypes'

type IconButtonProps = ButtonBaseProps & {
  icon?: ReactNode
  iconColor?: string
  rotate: 'right' | 'left' | 'up' | 'down'
  hover?: string
  active?: string
}

const rogationAngles = {
  right: '-rotate-90',
  left: 'rotate-90',
  up: 'rotate-180',
  down: 'rotate-none',
}

const IconButton: FC<IconButtonProps & RefAttributes<HTMLButtonElement>> =
  forwardRef<HTMLButtonElement, IconButtonProps>(
    (
      {
        dataTestId,
        onClick,
        icon,
        iconColor = 'text-portal-blue-900',
        rotate,
        hover = 'group-hover:bg-portal-neutral-50',
        active = 'group-active:bg-portal-neutral-400',
      },
      ref
    ) => {
      return (
        <button
          ref={ref}
          className={`flex justify-center items-center w-12 h-12 bg-transparent transition-all ease-in-out duration-150 ${hover} ${active} rounded-full p-s `}
          onClick={onClick}
          data-testid={dataTestId}
        >
          <div className={`${iconColor} ${rogationAngles[rotate]}`}>
            {icon ?? <ChevronDownIcon />}
          </div>
        </button>
      )
    }
  )
IconButton.displayName = 'IconButton'
export default IconButton
