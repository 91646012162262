import { useEffect, useState } from 'react'

// This hook returns bool value that screen is scrolled depending on scrollY param
export const useScreenScrolled = (scrollYParam: number) => {
  const [screenScrolled, setScrolled] = useState(false)

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > scrollYParam ? setScrolled(true) : setScrolled(false)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [screenScrolled, scrollYParam])

  return { screenScrolled }
}
