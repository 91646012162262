import { FC } from 'react'
import EnpalNameLogo from 'assets/icons/enpal-name-logo.svg'

interface EnpalLogoProps {
  isDark?: boolean
  logoTitle: string
}

const EnpalLogo: FC<EnpalLogoProps> = ({ isDark, logoTitle }) => {
  {
    /* 'text-enpal-logo' uses lineHeight: '23px' instead of line-height: 130% from design, 
    because props like 'leading-trim: both; text-edge: cap' are not suported yet by the browsers
    and lineHeight should be adjusted manualy by developer to match the design*/
  }
  return (
    <>
      <div className="flex items-end gap-1.25 pt-1.25">
        {
          <EnpalNameLogo
            className={`${isDark ? 'text-portal-blue-900' : 'text-white'}`}
          />
        }
        <span
          className={`${isDark ? 'text-portal-blue-900' : 'text-portal-neutral-50'} text-enpal-logo `}
        >
          {logoTitle}
        </span>
      </div>
    </>
  )
}

export default EnpalLogo
