'use client'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import LogoutIcon from 'assets/icons/v2/logout-icon.svg'
import { signOut } from 'next-auth/react'
import { FunctionComponent, ReactElement, useEffect, useMemo } from 'react'
import LANGUAGE_KEYS from 'utils/languageKeys'
import Image from 'next/image'
import IpoImage from 'assets/images/ipo-header-img.png'
import { pushToHistorySelector, clearHistorySelector } from 'store/historyStore'
import { useStore } from 'store/store'
import { checkFeatureFlag } from 'utils/feature-flags'
import Navigation from 'components/molecules/Navigation'
import { BACKBUTTON_ROUTES_MAP, getPageTitle } from '../../../../utils/routing'
import { useScreenScrolled } from 'hooks/useScreenScrolled'

interface INavigationHeaderProps {
  dictionary: { [key: string]: string }
  isAppUser: boolean | null
}

/**
 * Header component to provide navigation between pages.
 *
 * @returns {ReactElement} The rendered navigation header.
 */
const NavigationalHeader: FunctionComponent<INavigationHeaderProps> = ({
  dictionary,
  isAppUser,
}): ReactElement => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const pushToHistory = useStore(pushToHistorySelector)
  const clearHistory = useStore(clearHistorySelector)

  useEffect(() => {
    if (pathname) {
      pushToHistory(pathname)
    }
  }, [pathname, pushToHistory])

  /** Whether the user is currently at the installation overview page or not. */
  const isInstallationOverviewPage = useMemo(
    () => pathname === '/installation-overview',
    [pathname]
  )

  const { screenScrolled } = useScreenScrolled(150)

  const logout = () => {
    clearHistory()
    signOut({ callbackUrl: '/' })
  }

  // Check if grid ops shall be enabled.
  const is_ENABLE_GRIDOPS = checkFeatureFlag('ENABLE_GRIDOPS')

  const handleBackButtonClick = () => {
    const backRoute =
      (searchParams?.size ?? 0) > 0
        ? pathname
        : BACKBUTTON_ROUTES_MAP[pathname!]
    router.push(backRoute!)
  }

  const handleLogoClick = () => {
    router.push('/')
  }

  return (
    <div
      className={`w-full 
         ${isInstallationOverviewPage && is_ENABLE_GRIDOPS && 'sticky -top-36 md:-top-56 z-10'}
        `}
    >
      <Navigation
        portalLogoTitle={dictionary[LANGUAGE_KEYS.PORTAL_LOGO_TITLE]}
        onLogoClick={handleLogoClick}
        logoButtonTestId={'navbar-logo-button'}
        onBackButtonClick={handleBackButtonClick}
        backButtonDataTestId="navbar-back-button"
        isAppUser={isAppUser}
        rightPanelIconActions={[
          {
            icon: <LogoutIcon className="stroke-[1.5px]" />,
            dataTestId: 'logout-button',
            action: logout,
          },
        ]}
        subPageTitle={pathname && getPageTitle(pathname, dictionary)!}
      />
      {isInstallationOverviewPage && (
        <div className="flex items-center min-h-60 h-60 md:h-72 md:min-h-72 w-full overflow-hidden relative">
          <Image
            src={IpoImage}
            fill
            priority
            alt=""
            className="object-cover bg-dark-blue"
          />
          {!(is_ENABLE_GRIDOPS && screenScrolled) && (
            <div className="left-1/2 absolute flex items-center -translate-x-1/2 pb-9 flex-col text-center w-4/5">
              <div className="text-3xl md:text-5xl font-bold mb-3">
                <span className="text-white">
                  {dictionary[LANGUAGE_KEYS.IPO_HEADING]}
                </span>
                <span className="text-enpal-yellow">.</span>
              </div>
              <div className="text-enpal-yellow text-base md:text-2xl font-medium">
                {dictionary[LANGUAGE_KEYS.IPO_SUBLINE]}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default NavigationalHeader
