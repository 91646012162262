import { FC, MouseEventHandler, ReactElement, ReactNode } from 'react'
import MenuHamburger from 'assets/icons/menu-hamburger.svg'
import EnpalLogo from '../../atoms/EnpalLogo'
import IconButton from '../../atoms/Buttons/IconButton'

interface IconAction {
  icon: ReactElement
  action?: MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
}

interface NavBarProps {
  leftSlot: ReactNode
  midSlot: ReactNode
  rightSlot: ReactNode
}

interface NavigationProps {
  onBackButtonClick: MouseEventHandler<HTMLButtonElement>
  backButtonDataTestId: string
  portalLogoTitle: string
  onLogoClick: MouseEventHandler<HTMLButtonElement>
  logoButtonTestId: string
  subPageTitle?: string | null
  rightPanelIconActions: Array<IconAction>
  /** If hamburger is needed on mobile */
  isHamburgerMenu?: boolean
  isAppUser: boolean | null
}

const IconWrap: (props: {
  children: ReactElement
  action?: MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
}) => JSX.Element = ({ children, action, dataTestId }) => {
  return (
    <button
      className="flex justify-center align-middle w-12 h-12 pt-s pb-xs hover:border-b-4 hover:border-portal-yellow-400"
      onClick={action}
      data-testid={dataTestId}
    >
      {children}
    </button>
  )
}

const LogoButton: (props: {
  isAppUser: boolean | null
  onLogoClick: MouseEventHandler<HTMLButtonElement>
  portalLogoTitle: string
  dataTestId?: string
}) => JSX.Element = ({
  isAppUser,
  onLogoClick,
  portalLogoTitle,
  dataTestId,
}) => {
  return (
    <button
      className={`${isAppUser ? 'cursor-auto' : 'cursor-pointer'}`}
      onClick={(e) => !isAppUser && onLogoClick(e)}
      data-testid={dataTestId}
    >
      <EnpalLogo logoTitle={portalLogoTitle} />
    </button>
  )
}

const leftSlotSection = ({
  portalLogoTitle,
  onLogoClick,
  logoButtonTestId,
  isSubPage,
  onBackButtonClick,
  backButtonDataTestId,
  isAppUser,
}: {
  portalLogoTitle: string
  onLogoClick: MouseEventHandler<HTMLButtonElement>
  logoButtonTestId: string
  isSubPage: boolean
  onBackButtonClick: MouseEventHandler<HTMLButtonElement>
  backButtonDataTestId: string
  isAppUser: boolean | null
}) => (
  <>
    <div className="flex gap-m">
      {!isAppUser && isSubPage && (
        <div className="self-center">
          <IconButton
            iconColor="text-portal-neutral-50"
            rotate="left"
            onClick={onBackButtonClick}
            dataTestId={backButtonDataTestId}
            hover="hover:bg-portal-neutral-800"
            active="active:bg-portal-neutral-800"
          />
        </div>
      )}
      <div
        className={`self-center ${isSubPage ? 'hidden xs:flex' : 'flex ml-s'}`}
      >
        <LogoButton
          isAppUser={isAppUser}
          onLogoClick={onLogoClick}
          portalLogoTitle={portalLogoTitle}
          dataTestId={logoButtonTestId}
        />
      </div>
    </div>
  </>
)

const midSlotSection = (subPageTitle?: string | null) => (
  <>
    <div className={`hidden xs:flex grow`} />
    <div className="flex xs:hidden justify-self-center">
      {subPageTitle && (
        <div className="flex flex-row items-center gap-x-0.5 font-medium leading-enpal-tight ">
          <span className="text-portal-neutral-50 text-base">
            {subPageTitle}
          </span>
          <span className="text-portal-yellow-400 text-lg self-end">.</span>
        </div>
      )}
    </div>
  </>
)

const rightSlotSection: (props: {
  iconActions: Array<IconAction>
  isHamburgerMenu?: boolean
  isAppUser: boolean | null
}) => JSX.Element = ({ iconActions, isHamburgerMenu, isAppUser }) =>
  isAppUser ? (
    <div className="h-12" />
  ) : (
    <>
      <div className="flex justify-self-end">
        <div className="flex flex-row gap-m">
          {isHamburgerMenu ? (
            <IconWrap>
              <MenuHamburger className="text-white" />
            </IconWrap>
          ) : (
            iconActions.map((iconAction: IconAction, index) => (
              <IconWrap
                action={iconAction.action}
                dataTestId={iconAction.dataTestId}
                key={'iconAction' + index}
              >
                {iconAction.icon}
              </IconWrap>
            ))
          )}
        </div>
      </div>
    </>
  )

const NavBar: FC<NavBarProps> = ({ leftSlot, midSlot, rightSlot }) => {
  return (
    <>
      <nav className="flex justify-between items-center bg-portal-blue-900 p-xxs ">
        {leftSlot}
        {midSlot}
        {rightSlot}
      </nav>
    </>
  )
}

const Navigation: FC<NavigationProps> = ({
  backButtonDataTestId,
  portalLogoTitle,
  onLogoClick,
  logoButtonTestId,
  subPageTitle,
  rightPanelIconActions,
  onBackButtonClick,
  isHamburgerMenu,

  isAppUser,
}) => {
  return (
    <NavBar
      leftSlot={leftSlotSection({
        portalLogoTitle,
        onLogoClick,
        logoButtonTestId,
        isSubPage: subPageTitle !== undefined,
        onBackButtonClick,
        backButtonDataTestId,
        isAppUser,
      })}
      midSlot={midSlotSection(subPageTitle)}
      rightSlot={rightSlotSection({
        iconActions: rightPanelIconActions,
        isHamburgerMenu,
        isAppUser,
      })}
    />
  )
}

export default Navigation
