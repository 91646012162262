import LANGUAGE_KEYS from './languageKeys'

export const ROUTER_PATHS = {
  MAIN: '/',
  DOCUMENTS: '/documents-overview',
  ENERGY: '/energy-overview',
  FINANCIAL: '/financial-overview',
  INSTALLATION: '/installation-overview',
  LEASE: '/lease-overview',
  PERSONAL: '/personal-information',
  METER: '/meter-values',
}

export function getPageTitle(
  path: string,
  dictionary: { [x: string]: string }
) {
  const pathPageMap = {
    '/installation-overview': LANGUAGE_KEYS.IPO_TITLE,
    '/documents-overview': LANGUAGE_KEYS.START_DOCUMENTS_TILE_HEADING,
    '/energy-overview': LANGUAGE_KEYS.ENERGY_OVERVIEW_HEADING,
    '/financial-overview': LANGUAGE_KEYS.FINANCIAL_OVERVIEW_HEADING,
    '/meter-values': LANGUAGE_KEYS.METER_VALUES_HEADING,
    '/personal-information':
      LANGUAGE_KEYS.START_PERSONAL_INFORMATION_TILE_HEADING,
    '/damage-form': LANGUAGE_KEYS.DAMAGE_FORM_HEADING,
    '/hochwasser': LANGUAGE_KEYS.DAMAGE_FORM_HEADING,
  }

  const key = pathPageMap[path]
  return key ? dictionary[key] : undefined
}

export const BACKBUTTON_ROUTES_MAP = {
  [ROUTER_PATHS.DOCUMENTS]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.ENERGY]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.FINANCIAL]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.INSTALLATION]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.LEASE]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.PERSONAL]: ROUTER_PATHS.MAIN,
  [ROUTER_PATHS.METER]: ROUTER_PATHS.MAIN,
}
